<script>
  import { onMount } from 'svelte';

  export let target_element;
  let is_loaded=false
  let current_element
  const create_floating_element = () => {
    if(is_loaded)
      return
    is_loaded=true
    // Create floating element
    //const floating_element = document.createElement('div');
    //floating_element.innerText = 'Please select an option to continue';
    //floating_element.classList.add('floating-element');

    // Calculate position
    const { x, y, width } = target_element.getBoundingClientRect();
    current_element.style.left = `${x + width}px`;
    current_element.style.top = `${y}px`;
    // Append to body
    console.log("Created element with target", target_element)
    //target_element.parentElement.appendChild(floating_element);
  };

  onMount(() => {
    if (target_element) {
      create_floating_element();
    }
  });
</script>

<style>
  .floating-element {
    position: absolute;
    background-color: yellow;
    z-index: 1000;
    color: #4B0082;

  }
</style>


<div bind:this={current_element}>
  Please select to either `accept` or `deny` our suggested correction to continue.
</div>