<script>
  import ExpandablePill from "$lib/expandable_pill.svelte";
  import CallToAction from "$lib/call_to_action.svelte";
  let first_name = '';
  let last_name = '';
  let email = '';
  let message = '';
  let institution ='';
  let is_email_valid=true;
  let show_edu_prompt=false
  let unsettled=true;
  let submittable=true;
  let form_submitted=false;
  let cta_text;
  async function confirm_edu() {
    show_edu_prompt = false;

    // Your existing code to send data
    await handle_submit(false)
  }

  async function handle_submit(check_edu=true) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    submittable=false
    if (!emailRegex.test(email)) {
      is_email_valid = false;
      return;
    }
    if (check_edu && !email.endsWith('.edu')) {
      show_edu_prompt = true;
      return;
    }

    const payload = {

      first_name:first_name,
      last_name:last_name,
      email:email,
      institution: institution,
      message:message,
      call_to_action_text: cta_text
    };

    const res = await fetch(`${import.meta.env.VITE_API_URL}/upload/interest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    });

    if (res.ok) {
      console.log('Email sent');
      form_submitted=true

      setTimeout(()=>{
        form_submitted=false;
      }, 3000)
    }else{
      console.log("Ruh roh have", res)
    }
  }
</script>
<style>
  ::placeholder{
    color: #818181;
  }
  input{
    color: #00FFFF;
  }
  textarea{
    color: #00FFFF;
    min-height: 200px;
    min-width: 300px;
  }
  /* Styles for the modal background */
  .modal-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
  }
  .modal-bg.active {
    display: flex;
    animation: fadeIn 0.5s;
  }

  /* Styles for the actual modal content */
  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(0.9);
    opacity: 0;
    animation: popIn 0.5s forwards;
  }

  @keyframes fadeIn {
    from {
      background: rgba(0, 0, 0, 0);
    }
    to {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @keyframes popIn {
    0% {
      transform: scale(0.9);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
<h3>
  Request Full Product Access
</h3>
<CallToAction bind:text={cta_text}/>
{#if unsettled}
<!--ExpandablePill label="Request access"-->
  <form on:submit={handle_submit}>
  <input type="text" bind:value={first_name} placeholder="First name*" required>
  <input type="text" bind:value={last_name} placeholder="Last name*" required>
  <input type="email" bind:value={email} placeholder="Email*" required>
  <input type="text" bind:value={institution} placeholder="Institution Name*" required>
  <textarea bind:value={message} placeholder="Message or query (not required)"></textarea>
{#if submittable}
  <button type="submit">Request Access</button>
{/if}
  </form>
<!--/ExpandablePill-->
{#if show_edu_prompt}
  <div class="edu-prompt">
    <p>You're using a non-edu email. Are you sure that you want to proceed?</p>
    <button on:click={confirm_edu}>Yes</button>
    <button on:click={() => {show_edu_prompt = false; submittable=true}}>No</button>
  </div>
{/if}
{/if}

<div class="modal-bg {form_submitted ? 'active' : ''}">
  <div class="modal">
    Your request has been submitted!
  </div>
</div>