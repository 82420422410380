<script>
  import { fade,fly } from 'svelte/transition';
  import {flip} from 'svelte/animate'
  import { cubicOut } from 'svelte/easing';
  import {createEventDispatcher, onMount,} from 'svelte';
  import {default_persistence, has_opened_example} from "./store.js";
  import UserAttentionElement from '$lib/user_attention_element.svelte'
  import Graph from "$lib/graph.svelte";
  $: active_panels = [];
  export let expanded = false;
  //export let transition_between=[0]
  /*export let mistakes;*/
  let delay_threshold = 12000;
  let shared_timeWindow = 4000;
  let current_time;
  let visibility_queue = [];
  let timeouts=[];
  let check_intervals = []
  let check_blocked_intervals = []
  let timeout_too;
  $: seen_labels = []
  //let pkg
  let current_active_id = 0
  $: wait_times = {}
  let disp= createEventDispatcher()
  export let elements;
  export let transition_overlap_properties
  export let transition_dependents = {} // mapping of dependent to depends on
  let this_element=0;
  let outer_element
  //todo: finish persisting on click if we've made it through
  $: should_persist = $default_persistence;
  $:if(!should_persist){
    clear_timeouts();
  }
  let scroll_to_element
  $:if(expanded && scroll_to_element){
    scroll_to_element.scrollIntoView({behavior: "smooth", block:"start"});
  }
  let show_attention_grabber_for=-1
  let show_attention_grabber=false;
  let grab_cling;
  $:blocking_steps={}
  $: current_active_div=null
  //export let ;
  // TODO: fix math fonts
  //
  // safari
  $: _elements = !elements ? [] : elements.map((x, i) => ({ ...x, id: i }));

  $: instructor_stuff=elements?elements.filter((x)=>{return x.group==='instructor'}):[]
  $: student_stuff=elements?_elements.filter((x)=>{return x.group==='student'}):[]
  $: post_stuff=elements?_elements.filter((x)=>{return x.group==="post"}):[]

  $: panel_count = !_elements ? 0 : _elements.length;
  $: frontier = Math.max(active_panels)
  const map_deps = (td)=>{
    let r = {}
    Object.entries(td).forEach(([k,v])=>{
      if(!r.hasOwnProperty(v)){
        r[v] = []
      }
      r[v].push(k)
    })
    return r
  }
  $: restart_button_visible=false
  $: transition_triggers = map_deps(transition_dependents);
  let was_expanded=false
  $: if(expanded!==was_expanded) {
    if (expanded) {
      console.log("We have expanded t.f.", expanded)
      console.log("Adding expansion for panel since none is currently active with expanded and was expanded values",
        expanded, was_expanded
      )
      if(active_panels.length===0){
        add_panel(0)
      }
      disp('expand')
    }
    /*
    else {
    }*/
    was_expanded = expanded
  }


  onMount(() => {
    //pkg = window.transmittable_message
    console.log('HAve elements/effing panels', _elements, active_panels)
    _elements.forEach(x=>{
      if(x.props.hasOwnProperty('mistakes')){
        blocking_steps[x.id] = x.props.mistakes.length

      }
    });
    console.log("Have ", instructor_stuff, student_stuff, post_stuff)
  });
  function get_overlap_for_panel(panel_id){
    console.log("Getting overlap for panel", panel_id, _elements[panel_id].component)

    return  (
      transition_overlap_properties.hasOwnProperty(panel_id) &&
      transition_overlap_properties[panel_id].hasOwnProperty('overlap_time')
    ) ? transition_overlap_properties[panel_id].overlap_time : 4; //overlap_time

  }

  function clear_timeouts(){
    if(timeouts){
      timeouts.forEach(timeout=>clearTimeout(timeout))
    }
    timeouts=[]

  }

  function add_panel(id, from_click=false) {
    if(from_click) {
      if(
          show_attention_grabber_for >=0 && id < show_attention_grabber_for){
        if(active_panels.includes(id)){
          active_panels = active_panels.filter(x=>x!==id)
        }else{
          console.log("leaving cause we just activated this and we want to leave")
          active_panels = [...active_panels,id]
        }
        return
      }
      clear_timeouts()
    }else{
      if(show_attention_grabber_for >= 0 && id > show_attention_grabber_for){
        // this is probably wrong
        console.log("Leaving since thing should still be out")
        return;
      }
      //
      // if(!can_proceed_with_timing(id)){
      //   return
      // }
    }
    console.log(" next panel wait time for ", id)
    if (!active_panels.includes(id)) {
      if(id===0){
        if(active_panels.includes(panel_count - 1) && !should_persist || from_click){
          remove_panel(panel_count-1)
        }
      }else{
        if(active_panels.includes(id-1) && !should_persist &&
            show_attention_grabber_for !==id-1
            //&& !transition_dependents.hasOwnProperty(id-1)
        ){
          //todo: have transition dependables move
          let overlap_time = get_overlap_for_panel(id-1)
          timeouts.push(setTimeout(
            ()=>{
              remove_panel(id-1)
            }, overlap_time*1000)
          )
        }
      }
      active_panels = [...active_panels, id]
      console.log('active panels', active_panels)
      current_active_id = id
      if(!from_click) {
        let interval_id = setInterval(() => {
          if (wait_times.hasOwnProperty(id)) {
            clearInterval(interval_id);
            let index = check_intervals.indexOf(interval_id);
            if (index > -1) {
              check_intervals.splice(index, 1);
            }
            //this indirection is to ensure that wait_times are set
            proceed_with_timing(id, wait_times[id], from_click);
            console.log("id shitz for doing with wait time", id, wait_times[id])
          }
        }, 100);

        check_intervals.push(interval_id);
      }
      if(id===panel_count-1 &&!from_click){
        should_persist = true
      }
    }else{
      if(from_click){
        remove_panel(id)
        if(check_blocked_intervals){
          check_blocked_intervals.forEach(x=>clearTimeout(x))
        }
      }else if(id===panel_count-1){
        should_persist = true;
      }
    }
  }


  function check_mistakes_gone_maybe_resume_timing(which_id, which_interval_id){
    //console.log("element_div", outer_element_div)
    if(current_active_div===null){
      console.log("Leaving (since in timeInterval) due to no current_active_div")
      return
    }
    let stuff = outer_element.querySelector('.mistake-button');

    if(stuff){
      if(!show_attention_grabber){
        show_attention_grabber_for=which_id
        show_attention_grabber= true
        grab_cling=stuff
        stuff.classList.add('colored-wrapper')
      }
      console.log("Have mistakes not proceeding")
      return
    }

    console.log(`Adding panel cause no mistakes remaining ${which_id} ${stuff} `)
    show_attention_grabber=false
    show_attention_grabber_for=-1
    clearInterval(which_interval_id)
    let index = check_blocked_intervals.indexOf(which_interval_id);
    if (index > -1) {
      check_blocked_intervals.splice(index, 1);
    }
    //active_panels = []
    remove_panel(which_id)
    add_panel(which_id+1)

  }

  function can_proceed_with_timing(id){
    /*
    *  Checks if any blocks
    *
    * */

    console.log("Blocking steps", blocking_steps)
    if(blocking_steps.hasOwnProperty(id)) {
      //grab_cling = outer_element_div[id].querySelector('.mistake-container')
      //show_attention_grabber = true
      console.assert(id !== panel_count - 1, "Not expecting to have halting thing as last panel; need refinement")
      let interval_id = setInterval(() => {
        check_mistakes_gone_maybe_resume_timing(id, interval_id)
      }, 1000)
      check_blocked_intervals.push(interval_id
      )
      return false

    }
    return true
  }

  function proceed_with_timing(id, wt, from_click) {
    console.log("Blocking steps", blocking_steps)
    if(
        !can_proceed_with_timing(id) || from_click
        //blocking_steps.hasOwnProperty(id)
    ){
      console.log("Not adding stuff or removing ")
      return
    }
    let overlap_time = get_overlap_for_panel(id)
    let next_panel = (id === panel_count - 1) ? 0 : id + 1;
    if(next_panel===0){
      timeouts.push(setTimeout(() => {
        restart_button_visible=true
      }, wt * 1000 ));

    }else{
      console.log('overlap time', overlap_time, "Overall time", wt)
      timeouts.push(setTimeout(() => {
        add_panel(next_panel);
      }, wt * 1000 - overlap_time * 1000));
    }
  }
  function remove_panel(id) {
    if(!seen_labels.includes(id)){
      seen_labels = [...seen_labels, id]
    }
    active_panels = active_panels.filter(panel => panel !== id);
  }




</script>

<style>
  :root{
    --start-color: blueviolet;
    --end-color: #1BBB
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: gray;
    margin: 5px;
    cursor: pointer;
  }
  .active {
    background-color: blue;
  }
  .control-panel {
    display: flex;
    flex-direction: row;
  }
  .container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: margin 0.4s ease-in-out;
  }
  .inner-container{
    display: flex;
    flex-direction: column;
    overflow: visible;
    margin: 11px;
  }
  .continue-box{
    display: inline-block;
    float: right;
    vertical-align: top;

  }
  .label{
    max-width: 300px;
  }
  .focus-me{

  }
  .outermost-imbecile{
    transition: margin 0.4s ease-in-out, height 0.4s ease-in-out;
  }

  @keyframes -global-gradient-color-shift {
    0%, 100%{
      background: linear-gradient(90deg, var(--start-color) 25%, var(--end-color) 75%);
    }
    50% {
      background: linear-gradient(90deg, var(--end-color) 75%, var(--start-color) 25%);
    }
  }
  @keyframes -global-gradient-shift {
      0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }

  }

  .colored-wrapper {
    display: inline-block;
    background: linear-gradient(90deg, var(--start-color), var(--end-color), var(--start-color));
    animation: gradient-shift 5s infinite linear;

    background-size: 300% 300%;
    padding: 10px;
    border-radius: 8px;
  }
  .focused{
    margin: 30px 0;
  }
</style>
<div class={expanded ? "focused outermost-imbecile" : "outermost-imbecile"} bind:this={scroll_to_element}>
  <div style="left: 140px;">
    <Graph path1={instructor_stuff} path2={student_stuff} merge_path={post_stuff} active_labels={active_panels} seen_labels={seen_labels}
           on:toggle={e=>{add_panel(e.detail.id, true); e.stopPropagation()}}>
    </Graph>
  </div>
<div class="container" bind:this={outer_element}>
  {#if restart_button_visible}
    <div class="continue-box" title="Restart sequence" on:click={()=>{
      active_panels = [];
      add_panel(0, true);
      restart_button_visible=false;}}>
      <!-- Undo btn-->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" /><path fill="currentColor" d="M12.207 2.293a1 1 0 0 1 0 1.414L10.914 5H12.5c4.652 0 8.5 3.848 8.5 8.5S17.152 22 12.5 22S4 18.152 4 13.5a1 1 0 1 1 2 0c0 3.548 2.952 6.5 6.5 6.5s6.5-2.952 6.5-6.5S16.048 7 12.5 7h-1.586l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0z"/></svg>

    </div>
  {/if}
  {#each _elements as element, i (element.id)}
    {#if active_panels.includes(i)}
    <div in:fly={{x:500,y:0}} out:fly={{x:-500,y:0}}>
      <div class="inner-container" bind:this={current_active_div}>
        <!-- {outer_element_div[i]}-->
      <div>
        <div>
          <!-- transition:fade={{ duration:800, easing: cubicOut }} -->
        {#if element.label}
          <p class="label">{element.label}</p>
        {/if}
        <svelte:component
            this={_elements[i].component} {..._elements[i].props}
            bind:wait_time={wait_times[i]}>
        </svelte:component>
        {#if show_attention_grabber && show_attention_grabber_for===i}
        <!-- Note that by construction there should only ever be one of these  style='animation: gradient-shift 5s infinite linear'-->
          <div class="colored-wrapper">

        <UserAttentionElement  target_element={grab_cling} >

        </UserAttentionElement>
          </div>
        {/if}
      </div>
      </div>
      </div>
    </div>
    {/if}
  {/each}


</div>
<!--{#if mistakes}-->

<!--{/if}-->
  <!--
<div class="control-panel">
  {#if panel_count && panel_count > 0}
    {#each Array(panel_count).fill(0) as _, index}
      <div
          class="circle {active_panels.includes(index) ? 'active' : ''}"
          on:click={() => {add_panel(index, true)}}>
      </div>
    {/each}
  {/if}
</div>-->

</div>