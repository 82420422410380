<script>
  import {onMount} from "svelte";

  export let mistake
  //import Mistake from 'transmittable'
 let pkg
  export let which;
  export let s_delay_per = 0.8125;
  let run;
  let is_set = false;
  // todo: ensure that outer thing doesn't spawn until it should (effectively )
  const make_mistake = (div)=>{
    if(!mistake){
      console.log("dahfuq no mistake")
      return
    }
    if(!pkg) {
      console.log("")
      return
    }
    let rmistake = new pkg.Mistake().deserialize(mistake)
    rmistake.display()
    is_set =true

  }
  let dah_div

  onMount(()=>{
    console.log("Have transmittable")
    pkg = window.transmittable_message
    console.log("package", pkg, " on window ", window)
    run = setTimeout(()=>{
      make_mistake(dah_div)
    }, s_delay_per* (which +1)* 1000
    )
  })


  //$: make_mistake()
</script>


<!--Need a fake thing to mount probably-->

<div bind:this={dah_div}></div>