<script>
  import {createEventDispatcher, onMount} from "svelte";

  export let path1 = [];
  export let path2 = [];
  export let merge_path = [];
  export let active_labels=[];
  export let seen_labels=[];
  const disp= createEventDispatcher()
  function node_clicked(id) {
    //alert(`Node ${id} clicked.`);
    console.log("Clicking ", id)
    disp('toggle', {id})
  }


  $: path1_relative_scaling = path2.length>0 && path1.length>0 && path1.length < path2.length? (path2.length-1) / (path1.length-1) *100: 100;
  $: path2_relative_scaling = path1.length>0 && path2.length>0 && path2.length < path1.length? (path1.length-1) / (path2.length-1)* 100: 100;
  $: total_path_displacement = path2.length>0?100 * (Math.max(path2.length, path1.length)-1) : path1.length;
  $: path1_width=150;
  $: extra_displacement_horizontal =100
  $: path1_start=30;
  $: paths_x_start=50;
  $: padding =10;
  $: text_padding=20;
  $: path2_start= path1_start+ path1_width + padding
  $: merge_path_start=path1_start + path1_width/2
  $: text_x_offset=-35;
  function get_class_for_i(i, is_text=true){
    if(is_text)
      return (active_labels.includes(i)? "active": "seen") + " label"

    return (active_labels.includes(i)? "active": "seen") + " node"
  }
  onMount(()=>{
    console.log('have paths', path1, path2, merge_path)
  })


</script>
{#if path1 && path2 && merge_path}
<div style={`position: relative; width:  ${total_path_displacement + extra_displacement_horizontal + merge_path.length*100+extra_displacement_horizontal}px; height: 250px;`}>
  <!-- SVG for edges -->
  <svg width="600px" height="300px" style="position: absolute; top: 0; left: 0; z-index: 0;">
    {#each path1 as start, i }
      {#if i < path1.length - 1}
        <!-- Edges for path1 -->
        <path d={`M ${(i * path1_relative_scaling) + 10 + paths_x_start} ${path1_start} L ${((i + 1) * path1_relative_scaling)+10 + paths_x_start} ${path1_start}`} fill="transparent" stroke="gainsboro" stroke-width="2"/> <!--marker-end="url(#arrow)" />-->
      {/if}
    {/each}
    {#each path2 as start, i }
      {#if i < path2.length - 1}
        <!-- Edges for path2 -->
        <path d={`M ${(i * path2_relative_scaling) + 10 + paths_x_start} ${path2_start} L ${((i + 1) * path2_relative_scaling)+10 + paths_x_start} ${path2_start}`} fill="transparent" stroke="gainsboro" stroke-width="2"/> <!--marker-end="url(#arrow)" />-->
      {/if}
    {/each}
    {#each merge_path as start, i }
      {#if i < merge_path.length - 1}
        <!-- Edges for merge_path -->
        <path d={`M ${(total_path_displacement) + 110 + (i * 100) + paths_x_start} ${merge_path_start} L ${(total_path_displacement)  + paths_x_start + 110 + ((i + 1) * 100)} ${merge_path_start}`} fill="transparent" stroke="gainsboro"  />
      {/if}
    {/each}
    <!-- Bezier curves for merging Note that the last 110 is for height-->
    <path d={`M ${(total_path_displacement) + 10 + paths_x_start} ${path1_start} C ${(total_path_displacement) + 60 + paths_x_start} ${path1_start} ${(total_path_displacement) + 60 + paths_x_start} ${merge_path_start} ${(total_path_displacement) + 110 + paths_x_start}  ${merge_path_start}`} fill="transparent" stroke="gainsboro"  stroke-width="2"  />
    <path d={`M ${(total_path_displacement) + 10 + paths_x_start} ${path2_start} C ${(total_path_displacement) + 60 + paths_x_start} ${path2_start} ${(total_path_displacement) + 60 + paths_x_start} ${merge_path_start} ${(total_path_displacement) + 110 + paths_x_start}  ${merge_path_start}`} fill="transparent" stroke="gainsboro" stroke-width="2" />


  </svg>

  <!-- Divs for nodes -->
  {#each path1 as node, i}
    {#if active_labels.includes(node.id)}
    <div class="active node" style={`top: ${path1_start-padding}px; left: ${(i * path1_relative_scaling) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
      {:else}

      <div class="node" style={`top: ${path1_start-padding}px; left: ${(i * path1_relative_scaling) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
      {/if}
    {#if active_labels.includes(node.id)}
      <div class="active label" style={`top: ${path1_start+text_padding}px; left: ${((i) * path1_relative_scaling) + paths_x_start+ text_x_offset}px;`}  >
      {node.name}
    </div>
    {:else if seen_labels.includes(node.id)}
      <div class="seen label" style={`top: ${path1_start+text_padding}px; left: ${((i) * path1_relative_scaling) + paths_x_start+ text_x_offset}px;`}  >
        {node.name}
      </div>

    {/if}
  {/each}
  {#each path2 as node, i }
    {#if active_labels.includes(node.id)}
    <div class="active node" style={`top: ${path2_start-padding}px; left: ${(i * path2_relative_scaling) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
    {:else}
    <div class="node" style={`top: ${path2_start-padding}px; left: ${(i * path2_relative_scaling) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
    {/if}
    {#if active_labels.includes(node.id)}
    <div class="active label" style={`top: ${path2_start+text_padding}px; left: ${((i) * path2_relative_scaling) + paths_x_start+ text_x_offset}px;`} >
      {node.name}
    </div>
      {:else if seen_labels.includes(node.id)}
      <div class="seen label" style={`top: ${path2_start+text_padding}px; left: ${((i) * path2_relative_scaling) + paths_x_start + text_x_offset}px;`} >
        {node.name}
      </div>

      {/if}
  {/each}
  {#each merge_path as node, i}
    {#if active_labels.includes(node.id)}
      <div class="active node" style={`top: ${merge_path_start-padding}px; left: ${(total_path_displacement) + 100 + (i * 100) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
    {:else}
      <div class="node" style={`top: ${merge_path_start-padding}px; left: ${(total_path_displacement) + 100 + (i * 100) + paths_x_start}px;`} on:click={() => node_clicked(node.id)}></div>
    {/if}
    {#if active_labels.includes(node.id) }
    <div class="active label" style={`top: ${merge_path_start + text_padding}px; left: ${(total_path_displacement) + 80+(i * 100) + paths_x_start}px;`} on:click={()=>node_clicked(node.id)}>
      {node.name}
    </div>

    {:else if seen_labels.includes(node.id)}
    <div class="seen label" style={`top: ${merge_path_start + text_padding}px; left: ${(total_path_displacement) + 80+(i * 100) + paths_x_start}px;`} on:click={()=>node_clicked(node.id)}>
      {node.name}
    </div>

    {/if}
  {/each}
</div>
{/if}
<style>
  .node {
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #818181;

    filter: blur(5px);
    stroke: gainsboro;
    /*top: -20px;
    left: -20px;
    text-align: center;*/
    border-radius: 50%;
    z-index: 1;
  }


  .node:hover{
    filter: none
  }

  .node.active{
    background-color: #4B0082;

  }
  .label{
    line-height: 20px;
    position: absolute;
    max-width: 100px;
    overflow: visible;
    text-align: center;
  }
  .label.seen{
    color: gray;
  }
  .label.active{
    color: cyan;
  }

</style>