import { writable } from 'svelte/store';

const interaction_store = writable([]);

export const add_interaction = (type, id, timestamp) => {
  console.log("Adding ", type, id, timestamp)
  interaction_store.update(interactions => [...interactions, { type, id, timestamp }]);
};

export default interaction_store;
