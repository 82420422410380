<script>
  import AtomicEquation from '$lib/atomic_equation.svelte'
  import AtomicMistake from '$lib/atomic_mistake.svelte'
  import Panel from '$lib/panels.svelte'
  import ExpandablePill from "./expandable_pill.svelte";
  import {createEventDispatcher, onMount} from "svelte";
  import {has_opened_example} from "./store.js";
  import Image from '$lib/image.svelte'
  export let label;
  export let img_src;
  export let student_src;
  export let equation;
  export let equation_desc;
  export let equation_edit_sequence;
  export let sol_delta;
  export let student_edits;
 // export let edits;
  export let instructor_mistakes=undefined
  export let student_solution=undefined;
  let panel
  //export let mistakes;
  export let width='auto';
  export let starts_expanded=false
  $: expanded = starts_expanded;
  let last_expanded_state = false
  let equation_is_loaded = false
  let solution_image_width = '350px'
  onMount(()=>{
    equation_is_loaded=true
  })
  $: active_panel = 0
  // Note that the following needn't be interactive
  $: transition_overlap_properties ={}
  //new Mistake.deserialize(j_mistake).display()

  const handle_active_panel_change = (event) => {
    active_panel = event.detail.index;
  };

  $: components = make_components()
  $: panel_count = components.length
  const disp = createEventDispatcher()

  function make_components(){
    let should_show_points = !!student_src || !!student_solution
    let person = should_show_points? 'instructor' : 'student'
    let capital_person= should_show_points?'Instructor': "Student"
    let part = /[aehiouy]/.test(person[0])? 'an' : 'a'
    let initially_is_instructor = !!(instructor_mistakes || student_src||student_solution);

    let comps = [{
      component: Image,
      props: {
        src: img_src,
        alt:`Handwritten representation of the solution to the problem ${equation_desc}`,
        width: solution_image_width,//'400px',
      },
      label:`An image of math work is uploaded by ${part} ${person}`,
      group: initially_is_instructor? 'instructor': 'student',
      name: `${capital_person} Uploads`,
      },]
    if(instructor_mistakes){
      transition_overlap_properties[comps.length] = {
        overlap_time: 9,
      }
      comps.push({
        component: AtomicEquation,
        props: {
          equation: equation,
          mistakes: instructor_mistakes,
          show_points: should_show_points, // apparently this is equivalent to ternary
        },
        label: `We interpret the instructor's intended meaning and identify potential errors.`,
        group: initially_is_instructor? 'instructor': 'student',
        name: `${capital_person} Corrects`,
      })
    }else{
      transition_overlap_properties[comps.length] = {
        overlap_time: 9,
      }
      comps.push(
         {
      component: AtomicEquation,
      props: {
        equation: equation,
        show_points: should_show_points,
      },
      label: `We propose our guess as to the ${person}'s intended meaning`,
      group: initially_is_instructor? 'instructor': 'student',
      name: `${capital_person} Corrects`,
    }
      )
    }


    if(equation_edit_sequence){
      comps.push({
        component: AtomicEquation,
        props: {
          equation: equation_edit_sequence,
        },
        label: `The ${person} corrects our interpretation`,
        group: initially_is_instructor? 'instructor': 'student',
        name: `${capital_person} Edits`
      })
    }
    if(student_src){
      comps.push({
        component: Image,
        props: {
          src: student_src,
          width: solution_image_width,//'400px',
        },
        label: `The student submits an image`,
        group: 'student',
        name: 'Student Uploads'
      })
    }
    if(student_solution){
      let cap = 'Edits & Submits'
      if(student_edits){
        cap = 'Edits'
      }
      comps.push({
        component: AtomicEquation,
        props: {
          equation: student_solution
        },
        label: `The student submits a solution`,
        group: 'student',
        name: 'Student ' + cap
      })
    }
    if(student_edits){
      comps.push({
        component: AtomicEquation,
        props: {
          equation: student_edits
        },
        label: `The student edits their solution`,
        group: 'student',
        name: 'Student Submits'

      })

    }

    if(sol_delta){
      comps.push({
        component: AtomicEquation,
        props: {
          equation: sol_delta
        },
        label: `The student receives feedback.`,
        group:'post',
        name: 'Student Receives Feedback'
      })
    }
    comps.forEach((x,i)=>{x.id=i})
    console.log(comps)
    // todo: come up with student version
    // todo:handle mistakes (don't know where to put em, probably
    return comps
  }

</script>
<style>
  img {
      /*width: var(--img-width, auto);  // Use CSS variable to set width*/
    height: auto;                   /* Maintain aspect ratio*/
    display: block;                 /* Remove any potential whitespace below the image*/
  }
  .show {
    display: block;
  }

  .hide {
    display: none;
  }
  .outer{
    margin-top: 20px;
  }
</style>
<div class="outer">
<ExpandablePill label={label} bind:expanded={expanded}
                on:expand={(e)=>{expanded=e.detail; $has_opened_example=true}}>
  <Panel bind:this={panel} elements={components} bind:expanded={expanded}
         transition_overlap_properties={transition_overlap_properties}/>
  <!--{(e)=>{expanded}} -->
</ExpandablePill>
</div>

