<script>
  import {createEventDispatcher} from "svelte";

  export let label;// = "Click me"; // Default text label
  let expanded = false;          // State to track the expansion of the pill
  let show_child = false;         // State to track the visibility of the child content
  let disp = createEventDispatcher()




</script>

<style>
  .pill {
      position: relative;
      padding: 10px 20px;
      background-color: #007BFF;
      border-radius: 20px;
      color: white;
      cursor: pointer;
      transition: width 0.3s ease-in-out;
      width: fit-content;
      /*overflow: hidden ;*/
      white-space: nowrap;
      /*max-width: 100px;*/
  }

  .pill.expanded {
      max-width: 500px;
  }

  .child-content {
      /*display: none;        /* Initially hidden*/
    height: 0;
    overflow: hidden;
  margin-top: 10px;
    transition: all 0.3s ease;
  }

  .child-content.visible {
    height: auto;
    opacity: 1;
      /*display: block;*/
  }

  .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
    /*background-color: red;*/
      border-radius: 50%;
      padding: 2px 5px;
      display: none;
      cursor: pointer;
  }

  .close-btn.visible {
      display: block;
  }
</style>

<div class="pill" on:click={() => { expanded = !expanded; show_child = expanded; disp('expand',{expanded})}}>
  {label}
  <div class="close-btn" on:click={(e) => { e.stopPropagation(); show_child = false; disp('expand',{expanded})}}
       class:visible={expanded}>-</div>
</div>

<div class="child-content" class:visible={show_child}>
  <slot />
  <!--  user reports
  behavior they're seeing
  desired behavior
  --->


  <!--
  On Timeline
    within 3 business days (barring force majore)
    Class of bug dependence
  -->
  <!--Some expandable content that shows upon clicking the pill and hides upon
   clicking the '-'.-->
</div>