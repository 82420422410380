<script>
  import {default_time_per_panel} from "./store.js";

  export let src
  export let alt
  export let width;
  export let wait_time=$default_time_per_panel
</script>
<style>
  /*
  img{
    position:relative;
  }
  */
</style>
<img style="width: {width}; height: auto;" src={src} alt={alt}/>
