<script>

let variants=[
  "In the flow of teaching, grading shouldn't be a battle. Share your contact details and we'll show you the way " +
  "to effortless assessment. The opportunity awaits, when you're ready to seize it.",
    "Amidst the diurnal choreography of pedagogy, let not the task of grading evolve into " +
    "a nettlesome leviathan. Kindly bestow upon us your contact particulars; we shall unspool " +
    "for you a tableau of serene, facile assessment. Your opportunity, like a butterfly, flits " +
    "in the garden of choices—alight upon it at your leisure.",
  "Streamline the chore of grading without diluting its essence. " +
  "Provide your contact details and we'll introduce you to an autonomous" +
  " system that's almost as thoughtful as you are. Your time is valuable; choose when to" +
  " reclaim it.",
    "In the tireless pursuit of educational supremacy, let not the archaic methods of grading hold us back from" +
    " achieving greatness. Unite your contact information with our initiative, and together we shall revolutionize " +
    "the very fabric of academic assessment! This is more than an offer; it is a clarion call for progress in this era " +
    "of unprecedented opportunity. Seize the future today—it awaits only those bold enough to grasp it.",


    "Autonomous grading is like a self-driving car for education. It's the future, and it's here today."+
    "Sign up for a free trial of our autonomous grading website and see for yourself. You won't be disappointed."+
    "(And if you are, well, we're just a couple of physicists. What do we know?)"




]
function get_random_int(max) {
  return Math.floor(Math.random() * max);
}
export let text = variants[get_random_int(variants.length-1)]
//random_which = get_random_int(variants.length-1)

</script>

<p>
  {text}
</p>

